import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/**
 * The React Link component doesn't allow buttons.
 * This component provides a link or a button.
 * 
 * Set `isExternal` to true (default: false) to get a Link.
 */
class CustomLink extends Component {
  static defaultProps = {
    isExternal: false
  }

  render() {
    return (
      <React.Fragment>
        {this.props.isExternal ? (
          <a href={this.props.to} target='_blank' rel='noopener noreferrer'>
            {this.props.children}
          </a>
          ) : (
          <Link to={this.props.to}>
            {this.props.children}
          </Link>
          )
        }
      </React.Fragment>
    );
  }
}

export { CustomLink };