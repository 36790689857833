import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import faqs from '../data/faqs';

class Accordion extends React.Component {
  constructor() {
    super();
    this._handleClick = this._handleClick.bind(this);
  }
  
  componentDidMount() {
    this._handleClick();
  }
  
  _handleClick() {
    const acc = this._acc.children;
    for (let i = 0; i < acc.length; i++) {
      let a = acc[i];
      a.onclick = () => a.classList.toggle('active');
    }
  }
  
  render() {
    return (
      <div 
        ref={a => this._acc = a} 
        onClick={this._handleClick}>
        {this.props.children}
      </div>
    )
  }
}

class Faq extends React.Component {


  render() {
    return (
      <section className='faq' id='faq'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-10 offset-lg-1'>
              <h2 className='text-center'>
                <FormattedHTMLMessage id='faq.title' defaultMessage='Fragen und Antworten zur Mission' description='Fragen und Antworten zur Mission' />
              </h2>
              
              <Accordion>
                {faqs.map((faq, index) => (
                  <div className='accor' key={'faqItem' + index}>
                    <div className='head'>
                      <FormattedHTMLMessage id={faq.titleId} defaultMessage={faq.titleDefaultMessage} description={faq.titleDescription} />
                    </div>
                    <div className='body'>
                      <FormattedHTMLMessage id={faq.textId} defaultMessage={faq.textDefaultMessage} description={faq.textDescription} />
                    </div>
                  </div>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Faq;


