import React from 'react';
import Slider from 'react-slick';
import { FormattedHTMLMessage } from 'react-intl';
import { HashLink as Link } from 'react-router-hash-link';
import { injectIntl } from 'react-intl';

import { ReactComponent as CloseBTN } from './images/ICN_close_hauptnavigation.svg';
import iznMetapherImage from './images/ICN_metapher_icon.svg';

class MetaphorSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <React.Fragment>
        <div className='closeMetaphor'>
          <Link to='/#interactive'><CloseBTN /></Link>
        </div>
        <div className='metaphorSlider'>
          <Slider {...settings}>
            <div className='slide'>
              <div className='slide-content-container'>
                <h3>
                  <FormattedHTMLMessage id='metaphor.step1.title' defaultMessage='text' description='text'/>
                </h3>
                <div className='slideContent'>
                  <div className='metaphor-icon-text'>
                    <img className='metaphor-icon-text__image' alt='ICN Metapher' src={iznMetapherImage} />
                    <div className='metaphor-icon-text__text'>
                      <FormattedHTMLMessage id='metaphor.slider.icontext' defaultMessage='text' description='text'/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='slide'>
              <div className='slide-content-container'>
                <h3>
                  <FormattedHTMLMessage id='metaphor.step2.title' defaultMessage='text' description='text'/>
                </h3>
                <div className='slideContent'>
                  <div className='line'>
                    <FormattedHTMLMessage id='metaphor.slider.scale' defaultMessage='3 mm' description='3 mm'/>
                  </div>
                  <div className='line'></div>
                  <div className='line'></div>
                  <div className='ocean'>
                    <div className='wave'></div>
                  </div>
                </div>
              </div>
            </div>

            <div className='slide'>
              <div className='slide-content-container'>
                <h3><FormattedHTMLMessage id='metaphor.step3.title' defaultMessage='text' description='text'/></h3>
                <div className='slideContent'>
                  <div className='line'>
                    <FormattedHTMLMessage id='metaphor.slider.scale' defaultMessage='3 mm' description='3 mm'/>
                  </div>
                  <div className='line'></div>
                  <div className='line'></div>
                  <div className='ocean'>
                    <div className='wave'></div>
                    <div className='wave'></div>
                  </div>
                </div>
              </div>
            </div>

            <div className='slide'>
              <div className='slide-content-container'>
                <h3><FormattedHTMLMessage id='metaphor.step4.title' defaultMessage='text' description='text'/></h3>
                <div className='slideContent skyline-people'>
                  <Link to='/metaphortool' className='button mt-3'>
                    <FormattedHTMLMessage id='metaphor.slider.buttontext' defaultMessage='3 mm' description='3 mm'/>
                  </Link>
                  <div className='ocean'>
                    <div className='wave'></div>
                    <div className='wave'></div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(MetaphorSlider);