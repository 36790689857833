import React, { Component } from 'react';

import MetaphorSlider from '../components/metaphorSlider.js'; 

class Metaphor extends Component {

  render() {
    return (
      <React.Fragment>

        <MetaphorSlider /> 
        
      </React.Fragment>
    );
  }
}

export default (Metaphor);