import React from 'react';
import Navigation from '../components/navigation.js';
import Footer from '../components/footer.js';
import FullText from '../components/fullText';

class Datenschutz extends React.Component {

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }

  render() {
    return (
      <React.Fragment>
        <Navigation
          rootLink = '/'
          isExternal = {true}
        />

        <FullText
          className = 'datenschutz'
          id = 'datenschutz'
          headlineId = 'datenschutz.headline'
          headlineDefaultMessage = 'Datenschutz'
          headlineDescription = 'Datenschutz'

          contentId = 'datenschutz.content'
          contentDefaultMessage = 'Datenschutz'
          contentDescription = 'Datenschutz'
        />

        <Footer />
      </React.Fragment>
    );
  }
}

export default (Datenschutz);