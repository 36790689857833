import React from 'react';
import ReactImageVideoLightboxCustom from './partials/ReactImageVideoLightboxCustom';

import teaser1 from './images/Teaserbild_01_GRACE-FO_Animation.jpg'; 
import teaser2 from './images/Teaserbild_02_Flechtner_Interview.jpg'; 
import teaser3 from './images/Teaserbild_03_GFZ_vor_Ort.jpg'; 
// import teaser4 from './images/Teaserbild_04_placeholder.jpg'; 
import ImageTextText from './partials/imagetext/imageTextText';


class ImageText extends React.Component {

  constructor() {
    super(...arguments);

    this.state = {
      lightboxOpen: false,
      lightbox1Open: false
    };

    this._onCloseCallback = this._onCloseCallback.bind(this);
  }

  _onCloseCallback (data, event) {
    if(event.target.classList.contains('lightbox-layer-background') || event.target.classList.contains('lightbox-layer-close')) {
      this.setState({
        [data]: false,
        lightboxOpen: false
      });
    }
  }

  render() {
    let boxClass = ['lightbox-layer'];
    if(this.state.lightboxOpen) {
      boxClass.push('visible');
    }
    return (
      <section className='imagetext' id='imagetext'>
        <div className='container'>
          <div className='teaser'>
            <div className='row'>
              <div className='col-lg-6 text-center'>
                <img className='teaser-image teaser-image-wide teaser-image-clickable' alt='' onClick={() => { this.setState({ lightboxOpen: true, lightbox1Open: true }); }} src={teaser1} />
              </div>
              <div className='col-lg-6'>
                <ImageTextText
                  headlineId = 'imagetext.teaser1.title'
                  imageId = 'imagetext.teaser1.text'
                  onClickFunction = {() => { this.setState({ lightboxOpen: true, lightbox1Open: true }); }}
                  buttonId = 'imagetext.teaser1.buttontext'
                />
              </div>
            </div>
          </div>

          <div className='teaser'>
            <div className='row'>
              <div className='col-lg-6 order-2 order-lg-1'>
                <ImageTextText
                  headlineId = 'imagetext.teaser2.title'
                  imageId = 'imagetext.teaser2.text'
                  onClickFunction = {() => { this.setState({ lightboxOpen: true, lightbox2Open: true }); }}
                  buttonId = 'imagetext.teaser2.buttontext'
                />
              </div>
              <div className='col-lg-6 order-1 order-lg-2 text-center'>
                <img className='teaser-image teaser-image-wide teaser-image-clickable' alt='' onClick={() => { this.setState({ lightboxOpen: true, lightbox2Open: true }); }} src={teaser2} />
              </div>
            </div>
          </div>

          <div className='teaser'>
            <div className='row'>
              <div className='col-lg-6 text-center'>
                <img className='teaser-image teaser-image-wide teaser-image-clickable' alt='' src={teaser3} onClick={() => { this.setState({ lightboxOpen: true, lightbox3Open: true }); }} />
              </div>
              <div className='col-lg-6'>
                <ImageTextText
                  headlineId = 'imagetext.teaser3.title'
                  imageId = 'imagetext.teaser3.text'
                  onClickFunction = {() => { this.setState({ lightboxOpen: true, lightbox3Open: true }); }}
                  buttonId = 'imagetext.teaser3.buttontext'
                  link = 'https://www.gfz-potsdam.de/srs-nya/'
                  linkId = 'imagetext.teaser3.buttontext2'
                />
              </div>
            </div>
          </div>
        </div>      

        <div className={boxClass.join(' ')} >   
          {
            this.state.lightbox1Open &&
            <ReactImageVideoLightboxCustom
              data={[
                { url: 'https://www.youtube.com/embed/xAYuNcCEmQ4?rel=0', type: 'video', altTag: 'placeholder video' }]}
              startIndex={0}
              showResourceCount={false}
              onCloseCallback={this._onCloseCallback.bind(this, 'lightbox1Open')} />
          }
          {
            this.state.lightbox2Open &&
            <ReactImageVideoLightboxCustom
              data={[
                { url: 'https://www.youtube.com/embed/dF4DjD0VcAM?rel=0', type: 'video', altTag: 'placeholder video' }]}
              startIndex={0}
              showResourceCount={false}
              onCloseCallback={this._onCloseCallback.bind(this, 'lightbox2Open')} />
          }
          {
            this.state.lightbox3Open &&
            <ReactImageVideoLightboxCustom
              data={[
                { url: './images/gfz_satellitenempfangsstation_00.jpg', type: 'photo', altTag: 'GFZ Satellitenstation', caption: 'Foto: C. Falck, GFZ' },
                { url: './images/gfz_satellitenempfangsstation_01.jpg', type: 'photo', altTag: 'GFZ Satellitenstation', caption: 'Foto: C. Falck, GFZ' },
                { url: './images/gfz_satellitenempfangsstation_02.jpg', type: 'photo', altTag: 'GFZ Satellitenstation', caption: 'Foto: C. Falck, GFZ' },
                { url: './images/gfz_satellitenempfangsstation_03.jpg', type: 'photo', altTag: 'GFZ Satellitenstation', caption: 'Foto: C. Falck, GFZ' },
                { url: './images/gfz_satellitenempfangsstation_04.jpg', type: 'photo', altTag: 'GFZ Satellitenstation', caption: 'Foto: C. Falck, GFZ' },
                { url: './images/gfz_satellitenempfangsstation_05.jpg', type: 'photo', altTag: 'GFZ Satellitenstation', caption: 'Foto: C. Falck, GFZ' },
                { url: './images/gfz_satellitenempfangsstation_06.jpg', type: 'photo', altTag: 'GFZ Satellitenstation', caption: 'Foto: C. Falck, GFZ' },
                { url: './images/gfz_satellitenempfangsstation_07.jpg', type: 'photo', altTag: 'GFZ Satellitenstation', caption: 'Foto: C. Falck, GFZ' },
                { url: './images/gfz_satellitenempfangsstation_08.jpg', type: 'photo', altTag: 'GFZ Satellitenstation', caption: 'Foto: C. Falck, GFZ' },
                { url: './images/gfz_satellitenempfangsstation_09.jpg', type: 'photo', altTag: 'GFZ Satellitenstation', caption: 'Foto: C. Falck, GFZ' },
                { url: './images/gfz_satellitenempfangsstation_10.jpg', type: 'photo', altTag: 'GFZ Satellitenstation', caption: 'Foto: C. Falck, GFZ' },
                { url: './images/gfz_satellitenempfangsstation_11.jpg', type: 'photo', altTag: 'GFZ Satellitenstation', caption: 'Foto: C. Falck, GFZ' },
                { url: './images/gfz_satellitenempfangsstation_12.jpg', type: 'photo', altTag: 'GFZ Satellitenstation', caption: 'Foto: C. Falck, GFZ' }]}
              startIndex={0}
              showResourceCount={false}
              onCloseCallback={this._onCloseCallback.bind(this, 'lightbox3Open')} />
          }
        </div>
      </section>
    );
  }
}

export default ImageText;