import React from 'react';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext'
import Close from '@material-ui/icons/Close';
import { CircularProgress } from '@material-ui/core';

const INITIAL_X = 0;

class ReactImageVideoLightboxCustom extends ReactImageVideoLightbox {
    swipeLeft() {
        var currentIndex = this.state.index;
        const nextLink = document.querySelector('.lightbox-layer .nextLink');
        const prevLink = document.querySelector('.lightbox-layer .prevLink');
        if (currentIndex > 0) {
            setTimeout(() => {
                this.setState({
                    index: currentIndex - 1,
                    swiping: false,
                    x: INITIAL_X,
                    loading: true
                });

                if (currentIndex - 1 > 0) {
                    if (nextLink) {
                        nextLink.classList.add('active');
                    }
                } else {
                    if (prevLink) {
                        prevLink.classList.remove('active');
                    }
                }
            }, 500);
        } else {
            this.reset();
        }
    }

    swipeRight() {
        const nextLink = document.querySelector('.lightbox-layer .nextLink');
        const prevLink = document.querySelector('.lightbox-layer .prevLink');
        var currentIndex = this.state.index;
        if (currentIndex < (this.props.data.length - 1)) {
            setTimeout(() => {
                this.setState({
                    index: currentIndex + 1,
                    swiping: false,
                    x: INITIAL_X,
                    loading: true
                });

                if (currentIndex + 1 < (this.props.data.length - 1)) {
                    if (prevLink) {
                        prevLink.classList.add('active');
                    }
                } else {
                    if (nextLink) {
                        nextLink.classList.remove('active');
                    }
                }
            }, 500);
        } else {
            this.reset();
        }

        
    }


    getResources() {
        var items = [];
        var data = this.props.data;
        for (var i = 0; i < data.length; i++) {
            var resource = data[i];
            if (resource.type === 'photo') {
                items.push(
                <div className='lightbox-layer-image-container'>
                    <img key={'lightboxImage' + i}
                    alt={resource.altTag}
                    src={resource.url}
                    style={{
                        pointerEvents: this.state.scale === 1 ? 'auto' : 'none',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        transform: `translate(${this.state.x}px, ${this.state.y}px) scale(${this.state.scale})`,
                        transition: 'transform 0.5s ease-out'
                    }}
                    onLoad={() => { this.setState({ loading: false }); }} />
                    {resource.caption && 
                    <figcaption>{resource.caption}</figcaption>
                    }
                </div>);
            }

            if (resource.type === 'video') {
                items.push(<iframe key={'video' + i}
                    width='560'
                    height='315'
                    src={resource.url}
                    frameBorder='0'
                    allow='autoplay; encrypted-media'
                    title={resource.title}
                    alt={resource.altTag}
                    allowFullScreen
                    style={{
                        pointerEvents: this.state.scale === 1 ? 'auto' : 'none',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        transform: `translate(${this.state.x}px, ${this.state.y}px)`,
                        transition: 'transform 0.5s ease-out'
                    }}
                    onLoad={() => { this.setState({ loading: false }); }}></iframe>);
            }
        }

        return items;
    }

    render() {
        var resources = this.getResources();
        var nextLinkStartClass = 'nextLink active';

        if (resources.length <= 1) {
            nextLinkStartClass = 'nextLink';
        }
        return (
            <div
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}
                onTouchEnd={this.handleTouchEnd}
                onClick={this.props.onCloseCallback}
                style={{
                    top: '0px',
                    left: '0px',
                    overflow: 'hidden',
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'rgba(0,0,0,1)'
                }}
                className='lightbox-layer-background'>

                {
                    this.props.showResourceCount &&
                    <div
                        style={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            padding: '15px',
                            color: 'white',
                            fontWeight: 'bold'
                        }}>
                        <span>{this.state.index + 1}</span> / <span>{this.props.data.length}</span>
                    </div>
                }

                <Close
                    style={{
                        position: 'absolute',
                        top: '0px',
                        right: '0px',
                        padding: '10px',
                        color: '#FFFFFF',
                        cursor: 'pointer',
                        fontSize: `${this.state.iconSize * 0.8}px`
                    }}
                    onClick={this.props.onCloseCallback}
                    className='lightbox-layer-close' />

                <div className='prevLink'>
                    <NavigateBefore
                        style={{
                            position: 'absolute',
                            left: '0px',
                            zIndex: 1,
                            color: '#FFFFFF',
                            cursor: 'pointer',
                            fontSize: `${this.state.iconSize}px`
                        }}
                        onClick={() => { this.swipeLeft(); }} />
                </div>
                <div className={nextLinkStartClass}>
                    <NavigateNext
                        style={{
                            position: 'absolute',
                            right: '0px',
                            zIndex: 1,
                            color: '#FFFFFF',
                            cursor: 'pointer',
                            fontSize: `${this.state.iconSize}px`
                        }}
                        onClick={() => { this.swipeRight(); }} />
                </div>

                {
                    this.state.loading &&
                    <CircularProgress
                        style={{
                            position: 'absolute',
                            color: '#FFFFFF'
                        }} />
                }

                {
                    resources[this.state.index]
                }
            </div>
        );
    }
}

export default ReactImageVideoLightboxCustom;