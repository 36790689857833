import React from 'react';

import Navigation from '../components/navigation.js';
import Footer from '../components/footer.js';
import FullText from '../components/fullText';


class Impressum extends React.Component {

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }

  render() {
    return (
      <React.Fragment>
        <Navigation
          rootLink = '/'
          isExternal = {true}
        />

        <FullText
          className = 'impressum'
          id = 'impressum'
          headlineId = 'impressum.headline'
          headlineDefaultMessage = 'Impressum'
          headlineDescription = 'Impressum'

          textId = 'impressum.text'
          textDefaultMessage = 'Impressum'
          textDescription = 'Impressum'

          contentId = 'impressum.content'
          contentDefaultMessage = 'Impressum'
          contentDescription = 'Impressum'
        />

        <Footer />
      </React.Fragment>
    );
  }
}

export default (Impressum);