import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

class Interactive extends React.Component {  
  render() {
    return (
      <section className='interactive' id='interactive'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-10 offset-lg-1 text-center'>
              <h2 className='mt-5 align-center'>
                <FormattedMessage id='interactive.title' defaultMessage='So funktioniert die Datenerhebung' description='So funktioniert die Datenerhebung'/>
              </h2>
              <p className='lead'>
                <FormattedMessage id='interactive.text' defaultMessage='So funktioniert die Datenerhebung' description='So funktioniert die Datenerhebung'/>
              </p>
              <Link to='/metaphor' className='button mt-3'>
                <FormattedMessage id='interactive.buttontext' defaultMessage='So funktioniert die Datenerhebung' description='So funktioniert die Datenerhebung'/>
              </Link>
            </div>
          </div>
        </div>
        <div className='ocean'>
          <div className='wave'></div>
          <div className='wave'></div>
        </div>
      </section>
    );
  }
}

export default injectIntl(Interactive);