const cities = [
  {
    name: "Berlin",
    size: "891,68"
  },
  {
    name: "Potsdam",
    size: "188,61"
  },
  {
    name: "Los Angeles",
    size: "1.214,90"
  },
  {
    name: "Stuttgart",
    size: "207,35"
  },
  {
    name: "München",
    size: "310,70"
  },
  {
    name: "Bremen",
    size: "325,56"
  },
  {
    name: "Hamburg",
    size: "755,22"
  },
  {
    name: "Wiesbaden",
    size: "203,93"
  },
  {
    name: "Schwerin",
    size: "130,52"
  },
  {
    name: "Hannover",
    size: "204,14"
  },
  {
    name: "Düsseldorf",
    size: "217,41"
  },
  {
    name: "Mainz",
    size: "97,74"
  },
  {
    name: "Saarbrücken",
    size: "167,09"
  },
  {
    name: "Dresden",
    size: "328,48"
  },
  {
    name: "Magdeburg",
    size: "201"
  },
  {
    name: "Kiel",
    size: "118,65"
  },
  {
    name: "Erfurt",
    size: "269,88"
  },
  {
    name: "Brüssel",
    size: "32,61"
  },
  {
    name: "Bonn",
    size: "141,10"
  }
]

export default cities