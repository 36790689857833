import React from 'react';
import { FormattedMessage } from 'react-intl';

class ImageTextText extends React.Component {

  static defaultProps = {
    linkTarget: '_blank',
    linkRel: 'noopener noreferrer'
  }

  render() {
    return (
      <div>
        <h2 className='teaser-headline'>
          <FormattedMessage id={this.props.headlineId} defaultMessage='Default Image Headline Message' description='Default Image Headline Description'/>
        </h2>
        <p className='lead'>
          <FormattedMessage id={this.props.imageId} defaultMessage='Default Image Text Message' description='Default Image Text Description' />
        </p>
        <button className='button textlink' onClick={this.props.onClickFunction}>
          <FormattedMessage id={this.props.buttonId} defaultMessage='Default Image Button Message' description='Default Image Text Description'/>
        </button>
        {
          this.props.link &&
          <a href={this.props.link} target={this.props.linkTarget} rel={this.props.linkRel} alt='' className='button textlink'>
            <FormattedMessage id={this.props.linkId} defaultMessage='Default Link Message' description='Default Link Description'/>
          </a>
        }
      </div>
    );
  }
}

export default ImageTextText;