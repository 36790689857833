import React from 'react';
import MetaphorTool from '../components/metaphorTool.js'; 

function MetaphorView() {
  return (
      <React.Fragment>

        <MetaphorTool /> 
        
      </React.Fragment>
  );
}

export default (MetaphorView);