import React from 'react';

import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import badge from './images/GFZ_headerbild_missionslogo_x2.png'; 


class Hero extends React.Component {
  render() {
    return (
      <section className='header' id='header'>
        <div className='container-fluid'>
          <div className='row no-gutters'>
            <div className='col-lg-12 text-center'>
              <div className='image-wrapper'>
                <img className='mission-badge' alt='Missions Badge'  src={badge} />
              </div>
              <div className='container'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='text-wrapper' id='mission'>
                      <h1 className='align-center'>
                        <FormattedMessage id='hero.title'
                          defaultMessage='Welcome to {what}'
                          description='Welcome header on app main page'
                          values={{ what: 'react-intl' }}/>
                      </h1>
                      <p className='lead'>
                        <FormattedHTMLMessage id='hero.text'
                          defaultMessage='To get started, edit <code>src/App.js</code> and save to reload.'
                          description='Text on main page'/>
                      </p>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default (Hero); 