import React, { Component } from 'react';
import { Route, BrowserRouter } from 'react-router-dom'
import { IntlProviderWrapper } from "./utils/IntlProviderWrapper";
// Routes
import routes from './data/routes';
// Styles
import './styles.scss';

class App extends Component {

  constructor(...args) {
    super(...args);

    this.routing = (
      <BrowserRouter>
        {routes.map((route, index) => (
          <Route
            key={'Route' + index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </BrowserRouter>
    )
  }

  render() {
    return (
      <IntlProviderWrapper>
        {this.routing}
      </IntlProviderWrapper>
    );
  }
}

export default App;
