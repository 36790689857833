import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { HashLink as Link } from 'react-router-hash-link';

import { ReactComponent as CloseBTN } from './images/ICN_close_hauptnavigation.svg';
import { injectIntl } from 'react-intl';
import badge from './images/GFZ_headerbild_missionslogo_x2.png'; 
import cities from '../data/cities';


function IntroText(props) {
  if (!props.visible) {
    return null;
  }

  return (
    <div className='text'>
      <FormattedHTMLMessage id='metaphor.step5.title' defaultMessage='text' description='text'/>
    </div>
  );
}

function OutroText(props) {
  if (!props.visible) {
    return null;
  }

  return (
    <div>
      <div className='text'>
        <FormattedHTMLMessage id='metaphor.step6.title' defaultMessage='text' description='text'/>
      </div>
      <div className='image-wrapper'>
        <img className='mission-badge' alt='Missions Badge'  src={badge} />
      </div>
    </div>
    );
}


class MetaphorTool extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      citySize: 0,
      year: 0, 
      waterHeight: 0,
      waterHeightNormed: 0,
      firstSlide: true,
      lastSlide: false,
      water: false,
      selected: '2002'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleLastSlide = this.handleLastSlide.bind(this);
    this.handleFirstSlide = this.handleFirstSlide.bind(this);
    this.setResult = this.setResult.bind(this);
  }
  
  handleChange(e) {
    const value = e.target.value.replace('.', '').replace(',', '.');
    this.setState({citySize: value},
      () => this.setResult());
      this.setState({lastSlide: false});
      this.setState({firstSlide: false});
      this.setState({water: true});
  }

  handleYearChange(filter) {
    this.setState({year: filter},
      () => this.setResult());
      this.setState({lastSlide: false});
      this.setState({firstSlide: false});
      this.setState({water: true});
  }

  handleFirstSlide(e) {
    this.setState({firstSlide: true});
    this.setState({lastSlide: false});
    this.setState({water: false});
    this.setFilter.bind(this, '2002');
  }

  handleLastSlide(e) {
    this.setState({lastSlide: true});
    this.setState({firstSlide: false});
    this.setState({water: false});
    this.setFilter.bind(this, '2025');
  }

  componentDidMount() {
    const selectElement = document.querySelector('.gfz-select');
    const value = selectElement.value.replace('.', '').replace(',', '.');
    this.setState({citySize: value});
    this.setResult();
  }

  setResult() {
    var size = this.state.citySize;
    var mass = this.state.year;
    var calc = (( -1 * mass ) / ( size ));
    
    var result = calc.toPrecision(3);
    var resultNormed = (( -1 * mass ) / ( size ) * 25).toFixed(0);

    if (result.split('.').length > 1) {
      if (result.split('.')[1].length > 3) {
        result = calc.toFixed(3);
      }
    }

    if(resultNormed < 0) {
      resultNormed = 0;
    }

    this.setState({waterHeight: result.replace('.', ','), waterHeightNormed: resultNormed});
  }
  
  getInitialState (){
    return {
      selected:''
    }
  }

  setFilter (filter) {
    this.setState({selected  : filter});
    var val;
    if(filter === '2002') {
      this.handleFirstSlide();
    } else if (filter === '2025') {
      this.handleLastSlide();
    } else {
      switch (filter) {
        case '2005':
          val = -400;
          break;
        case '2012':
          val = -1800;
          break;
        case '2019':
          val = -2400;
          break;
        default:
          val = -400;
      }
      this.handleYearChange(val);
    }
  }

  isActive (value){
    return 'btn '+((value===this.state.selected) ?'active':'default');
  }

  render() {
    const divStyle = {
      color: 'white',
      height: this.state.waterHeightNormed+'%',
      WebkitTransition: 'all', // note the capital 'W' here
      msTransition: 'all' // 'ms' is the only lowercase vendor prefix
    };

    let toolContentClassName = 'toolContent';
    if (this.state.water) {
      toolContentClassName += ' people';
    }
    if (this.state.lastSlide) {
      toolContentClassName += ' last-slide';
    }

    return (
      <React.Fragment>
        <div className='closeMetaphor'>
          <Link to='/#interactive'><CloseBTN /></Link>
        </div>
        <div className='metaphorTool'>
          <div className={toolContentClassName}>
            <IntroText visible={this.state.firstSlide} />
            <div className={this.state.water ? 'toolHeader' : 'hidden'}>
              <div className='gfz-select-container'>
                <select className='gfz-select' value={this.state.value} onChange={this.handleChange}>
                  {cities.map((city, index) => (
                    <option value={city.size} key={'option' + index}>{city.name} ({city.size} km²)</option>
                  ))}
                </select>
              </div>
            
              <div className='calculatedHeight'>{this.state.waterHeight} m</div>
            </div>
            <OutroText visible={this.state.lastSlide} />
            {this.state.water && 
              <div className='ocean' style={divStyle}>
                <div className='wave'></div>
                <div className='wave'></div>
              </div>
            }
            <div className='toolFooter'>
              <ul className={this.isActive()}>
                <li value='0' className={this.isActive('2002')} onClick={this.setFilter.bind(this, '2002')}>2002</li>
                <li value='-400' className={this.isActive('2005')} onClick={this.setFilter.bind(this, '2005')}>2005</li>
                <li value='-1800' className={this.isActive('2012')} onClick={this.setFilter.bind(this, '2012')}>2012</li>
                <li value='-2400' className={this.isActive('2019')} onClick={this.setFilter.bind(this, '2019')}>2019</li>
                <li value='-3100' className={this.isActive('2025')} onClick={this.setFilter.bind(this, '2025')}>2025</li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
 
  }
}

export default injectIntl(MetaphorTool);