import React from 'react';
import { FormattedMessage } from 'react-intl';

import teaser from '../data/teaser';
import TeaserComponent from './partials/teaser/teaserComponent';


class Teaser extends React.Component {
  render() {
    return (
      <section className='teaser-container' id='teaser'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h2 className='text-center'>
                <FormattedMessage id='teaser.title' defaultMessage='text' description='text'/>
              </h2>
            </div>

            {teaser.map((teaserItem, index) => (
              <TeaserComponent
                key={'teaserItem' + index}
                link={teaserItem.link}
                teaserImageSrc={teaserItem.teaserImageSrc}
                teaserHeadlineId={teaserItem.teaserHeadlineId}
                teaserTextId={teaserItem.teaserTextId}
                buttonTextId={teaserItem.buttonTextId}
              />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Teaser;