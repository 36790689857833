import React from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import de from 'react-intl/locale-data/de';
import sanitize from 'sanitize-html-react';

addLocaleData([...en, ...de]);

const Context = React.createContext();
let deTranslation;
let enTranslation;

/**
 * The IntlProviderWrapper loads the translations files and uses the
 * React IntlProvider to add them.
 * It also handles languages switches via Context:
 * @example
 * import { IntlContext } from '../utils/IntlProviderWrapper';
 * ...
 * const LanguageSwitch = (props) => {
 *  const { switchToEN, switchToDE } = React.useContext(IntlContext);
 *  ...
 *  <Link onClick={switchToDE} className='nav-link nav-link-lang' to='/'>
 *  ...
 * };
 * 
 */
class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args);

    let defaultLanguage = 'de';
    let defaultMessages = deTranslation;

    this.context = Context;

    this._switchLanguageToDE = this._switchLanguageToDE.bind(this);
    this._switchLanguageToEN = this._switchLanguageToEN.bind(this);
    this._loadTranslationFiles = this._loadTranslationFiles.bind(this);

    // pass everything in state to avoid creating object inside render method (like explained in the documentation)
    this.state = {
      locale: defaultLanguage,
      messages: defaultMessages,
      switchToEN: this._switchLanguageToEN, 
      switchToDE: this._switchLanguageToDE 
    };

    this._loadTranslationFiles();
  }

  /**
   * Loads the translation files via XMLHttpRequest.
   * Uses Promise for asynchronous loading.
   */
  _loadTranslationFiles () {
    const translationFiles = [
      {
        lang: 'de',
        url: 'translations/de.json'
      },
      {
        lang: 'en',
        url: 'translations/en.json'
      }
    ];

    Promise.all(translationFiles.map((obj_) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', obj_.url, true)
        xhr.onload = function() {
          if (xhr.readyState === 4) {
            if(xhr.status === 200) {
              const jsonObj = JSON.parse(xhr.responseText);
              for (let key in jsonObj) {
                // XSS
                jsonObj[key] = sanitize(jsonObj[key]);
              }
              switch (obj_.lang) {
                case 'en':
                  enTranslation = jsonObj;
                  break;
                case 'de':
                default:
                  deTranslation = jsonObj;
                  break;
              }
              resolve()
            } else {
              reject(xhr.statusText)
            }
          }
        };
        xhr.send(null);
      })
    })).then(() => {
      this.setState({ locale: 'de', messages: deTranslation });
    }).catch(err => console.error(err))
  }

  _switchLanguageToDE () {
    console.log('_switchLanguageToDE');
    this.setState({ locale: 'de', messages: deTranslation });
  }

  _switchLanguageToEN () {
    console.log('_switchLanguageToEn');
    this.setState({ locale: 'en', messages: enTranslation });
  }

  render() {
    const { locale, messages } = this.state;
    return (
      <Context.Provider value={this.state}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale='de'
        >
          {this.props.children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as IntlContext };