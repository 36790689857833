import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab, faCoffee);

class Sociallinks extends React.Component {
  render() {
    return (
      <section className='sociallinks' id='sociallinks'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <a className='sociallinks-link' href='https://twitter.com/GFZ_Potsdam' target='_blank' rel='noopener noreferrer' alt='GFZ Twitter'>
                <FontAwesomeIcon icon={['fab', 'twitter']} />
              </a>
              <a className='sociallinks-link' href='https://www.instagram.com/gfz_potsdam/' target='_blank' rel='noopener noreferrer' alt='GFZ Instagram'>
                <FontAwesomeIcon icon={['fab', 'instagram']} />
              </a>
              <a className='sociallinks-link' href='https://www.youtube.com/user/GFZvideos' target='_blank' rel='noopener noreferrer' alt='GFZ YouTube'>
                <FontAwesomeIcon icon={['fab', 'youtube']} />
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Sociallinks; 