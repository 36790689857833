const navigation = [
  {
    linkTo: "mission",
    id: "navigation.link1",
    defaultMessage: "Satellitenmission GRACE-FO",
    description: "Satellitenmission GRACE-FO"
  },
  {
    linkTo: "interactive",
    id: "navigation.link2",
    defaultMessage: "Eisschmelze auf Grönland",
    description: "Eisschmelze auf Grönland"
  },
  {
    linkTo: "chronology",
    id: "navigation.link3",
    defaultMessage: "Chronologie der Eisschmelze",
    description: "Chronologie der Eisschmelze"
  },
  {
    linkTo: "faq",
    id: "navigation.link4",
    defaultMessage: "Fragen und Antworten",
    description: "Fragen und Antworten"
  },
  {
    linkTo: "teaser",
    id: "navigation.link5",
    defaultMessage: "Weitere GFZ-Projekte",
    description: "Weitere GFZ-Projekte"
  }
]

export default navigation