const faqs = [
  {
    titleId: "faq.question1.title",
    titleDefaultMessage: "faq.question1.title",
    titleDescription: "faq.question1.title",
    textId: "faq.question1.text",
    textDefaultMessage: "faq.question1.text",
    textDescription: "faq.question1.text",
  },{
    titleId: "faq.question2.title",
    titleDefaultMessage: "faq.question2.title",
    titleDescription: "faq.question2.title",
    textId: "faq.question2.text",
    textDefaultMessage: "faq.question2.text",
    textDescription: "faq.question2.text",
  },{
    titleId: "faq.question3.title",
    titleDefaultMessage: "faq.question3.title",
    titleDescription: "faq.question3.title",
    textId: "faq.question3.text",
    textDefaultMessage: "faq.question3.text",
    textDescription: "faq.question3.text",
  },{
    titleId: "faq.question4.title",
    titleDefaultMessage: "faq.question4.title",
    titleDescription: "faq.question4.title",
    textId: "faq.question4.text",
    textDefaultMessage: "faq.question4.text",
    textDescription: "faq.question4.text",
  },{
    titleId: "faq.question5.title",
    titleDefaultMessage: "faq.question5.title",
    titleDescription: "faq.question5.title",
    textId: "faq.question5.text",
    textDefaultMessage: "faq.question5.text",
    textDescription: "faq.question5.text",
  },{
    titleId: "faq.question6.title",
    titleDefaultMessage: "faq.question6.title",
    titleDescription: "faq.question6.title",
    textId: "faq.question6.text",
    textDefaultMessage: "faq.question6.text",
    textDescription: "faq.question6.text",
  },{
    titleId: "faq.question7.title",
    titleDefaultMessage: "faq.question7.title",
    titleDescription: "faq.question7.title",
    textId: "faq.question7.text",
    textDefaultMessage: "faq.question7.text",
    textDescription: "faq.question7.text",
  },{
    titleId: "faq.question8.title",
    titleDefaultMessage: "faq.question8.title",
    titleDescription: "faq.question8.title",
    textId: "faq.question8.text",
    textDefaultMessage: "faq.question8.text",
    textDescription: "faq.question8.text",
  },{
    titleId: "faq.question9.title",
    titleDefaultMessage: "faq.question9.title",
    titleDescription: "faq.question9.title",
    textId: "faq.question9.text",
    textDefaultMessage: "faq.question9.text",
    textDescription: "faq.question9.text",
  }
]

export default faqs