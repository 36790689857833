import React from 'react';
import { IntlContext } from '../utils/IntlProviderWrapper';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

const LanguageSwitch = (props) => {
  const { switchToEN, switchToDE } = React.useContext(IntlContext);
  let link = <Link onClick={switchToEN} className='nav-link nav-link-lang' to='/'>
    <FormattedMessage id='navigation.langEN'
      defaultMessage='EN'
      description='EN' />
  </Link>;

  if (props.intl.locale === 'en') {
    link = <Link onClick={switchToDE} className='nav-link nav-link-lang' to='/'>
      <FormattedMessage id='navigation.langDE'
        defaultMessage='DE'
        description='DE' />
    </Link>;
  }

  return (
    <>
      {link}
    </>
  );
};

export default injectIntl(LanguageSwitch);
