import React from 'react';

import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';


class FullText extends React.Component {
  render() {
    return (
      <section className={this.props.className} id={this.props.id}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h1 className='text-center'>
                <FormattedMessage id={this.props.headlineId}
                  defaultMessage={this.props.headlineDefaultMessage}
                  description={this.props.headlineDescription} />
              </h1>
              {this.props.textId &&
              <p>
                <FormattedHTMLMessage id={this.props.textId}
                  defaultMessage={this.props.textDefaultMessage}
                  description={this.props.textDescription} />
              </p>
              }

              {this.props.contentId &&
              <p>
                <FormattedHTMLMessage id={this.props.contentId}
                  defaultMessage={this.props.contentDefaultMessage}
                  description={this.props.contentDescription}/>
              </p>
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default (FullText); 