import React from 'react';

import Navigation from '../components/navigation.js';
import Footer from '../components/footer.js';
import Hero from '../components/hero.js';
import ImageText from '../components/imagetext.js';
import Interactive from '../components/interactive.js';
import IFrameHolder from '../components/iframe.js';
import Faq from '../components/faq.js';
import Teaser from '../components/teaser.js';
import Sociallinks from '../components/sociallinks.js';

class Start extends React.Component {
  componentDidMount () {
    const hash = window.location.hash;

    // make sure page is scrolling to correct hash 
    // at the moment there is no other solution than this
    if(hash) {
      setTimeout(function () {
        const scrollY = window.scrollY;
        const element = document.getElementById(hash.replace('#', ''));
        let offsetTop = element ? element.offsetTop : 0;
        if (offsetTop > scrollY) {
          window.scrollTo(0, offsetTop);
        }
      }, 500);
    }
  }

  render() {
    return (
      <React.Fragment>
      
        <Navigation
          rootLink = '#root'
        />

        <Hero />

        <ImageText />

        <Interactive /> 

        <IFrameHolder /> 

        <Faq />

        <Teaser />

        <Sociallinks />

        <Footer />
        
      </React.Fragment>
    );
  }
}

export default (Start);