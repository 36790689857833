import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './utils/serviceWorker';
import App from "./App";
import smoothscroll from 'smoothscroll-polyfill';

/*
 * The Scroll Behavior specification has been introduced as an extension of the Window
 * interface to allow for the developer to opt in to native smooth scrolling.
 * To date this has only been implemented in Chrome, Firefox and Opera.
 * Start Polyfill:
 */
smoothscroll.polyfill();

/*
 * FIX for Mobile devices and fullscreen layer
 * from https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
function calcVH () {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

calcVH();

window.addEventListener('resize', () => {
  calcVH();
});

/*
 * Start Application
 */
ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
