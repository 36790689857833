import React from 'react';
import { FormattedMessage } from 'react-intl';

class IFrameHolder extends React.Component {
  render() {
    return (
      <section className='iframe' id='iframe'>
        <div className='container' id='chronology'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h2 className='mt-5 align-center'>
                <FormattedMessage id='iframe.title' defaultMessage='So funktioniert die Datenerhebung' description='So funktioniert die Datenerhebung'/>
              </h2>
              <div className='iFrameHolder'>
                <iframe title='chart' src='http://gravis.gfz-potsdam.de/eismassenverlust_greenland'></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default IFrameHolder;


