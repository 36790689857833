import React from 'react';
import Scrollchor from 'react-scrollchor';

import gfzImage from './images/GFZ_Logo.svg'; 
import helmholtzImage from './images/Helmholtz_Logo_138x42.svg'; 
import arrowImage from './images/ICN_back-to-top_arrows.svg';

import { FormattedMessage } from 'react-intl';
import { CustomLink as Link } from '../utils/customLink';

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this._setButtonSticky = this._setButtonSticky.bind(this);
  }

  componentDidMount() {
    const self = this;
    self._setButtonSticky();

    window.addEventListener('scroll', event => {
      self._setButtonSticky();
    });
  }

  _setButtonSticky() {
    const backToTopButton = document.querySelector('footer .backToTop');
    const navBar = document.querySelector('nav.navbar');
    if (backToTopButton) {
      const scrollTop = window.scrollY;

      const bodyElement = document.body;
      const htmlElement = document.documentElement;
      const footerElement = document.querySelector('footer');

      const footerHeight = footerElement ? footerElement.offsetHeight : 0;
      const pageHeight = Math.max( bodyElement.scrollHeight, bodyElement.offsetHeight, htmlElement.clientHeight, htmlElement.scrollHeight, htmlElement.offsetHeight ) - window.innerHeight;

      if (scrollTop < pageHeight - footerHeight && scrollTop > navBar.offsetHeight) {
        backToTopButton.classList.add('is-sticky');
      } else {
        backToTopButton.classList.remove('is-sticky');
      }
    }
  }

  render() {
    return (
      <footer>
        <Scrollchor to='#root' className='backToTop'><img src={arrowImage} alt='totop button' /></Scrollchor>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-3'>
              <Link to='https://www.gfz-potsdam.de/startseite/' isExternal={true}>
                <img alt='GFZ'  src={gfzImage} />
              </Link>
            </div>
            <div className='col-lg-3'>
              <Link to='/datenschutz'>
                <FormattedMessage id='datenschutz.footerlink' defaultMessage='Datenschutz' description='Datenschutz'/>
              </Link>
            </div>
            <div className='col-lg-3'>
              <Link to='/impressum'>
                <FormattedMessage id='impressum.footerlink' defaultMessage='Impressum' description='Impressum'/>
              </Link>
            </div>
            <div className='col-lg-3'>
              <Link to='https://www.helmholtz.de/' isExternal={true}>
                <img alt='Helmholtz'  src={helmholtzImage} />
              </Link>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;