import teaser1 from '../components/images/Teaserbild_04_GFZ_Forschungseinheiten.jpg';
import teaser2 from '../components/images/Teaserbild_05_NatureClimateChange_570x320.jpg'; 
import teaser3 from '../components/images/Teaserbild_06_Nasa_570x320.jpg'; 
import teaser4 from '../components/images/Teaserbild_07_DLR_570x320.jpg';

const teaser = [
  {
    link: "https://www.gfz-potsdam.de/forschung/themen/",
    teaserImageSrc: teaser1,
    teaserHeadlineId: "teaser.teaser1.title",
    teaserTextId: "teaser.teaser1.text",
    buttonTextId: "teaser.teaser1.buttontext"
  },{
    link: "https://www.nature.com/articles/s41558-019-0456-2",
    teaserImageSrc: teaser2,
    teaserHeadlineId: "teaser.teaser2.title",
    teaserTextId: "teaser.teaser2.text",
    buttonTextId: "teaser.teaser2.buttontext"
  },{
    link: "https://www.nasa.gov/missions/grace-fo",
    teaserImageSrc: teaser3,
    teaserHeadlineId: "teaser.teaser3.title",
    teaserTextId: "teaser.teaser3.text",
    buttonTextId: "teaser.teaser3.buttontext"
  },{
    link: "https://www.dlr.de/dlr/desktopdefault.aspx/tabid-10261/371_read-27789/#/gallery/30602",
    teaserImageSrc: teaser4,
    teaserHeadlineId: "teaser.teaser4.title",
    teaserTextId: "teaser.teaser4.text",
    buttonTextId: "teaser.teaser4.buttontext"
  }
]

export default teaser