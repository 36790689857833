import Start from '../pages/Start';
import Metaphor from '../pages/Metaphor';
import MetaphorView from '../pages/MetaphorView';
import Impressum from '../pages/Impressum';
import Datenschutz from '../pages/Datenschutz';

const routes = [
  {
      path: "/",
      exact: true,
      component: Start
  },
  {
      path: "/metaphor",
      component: Metaphor
  },
  {
      path: "/metaphortool",
      component: MetaphorView
  },
  {
      path: "/impressum",
      component: Impressum
  },
  {
      path: "/datenschutz",
      component: Datenschutz
  }
]

export default routes