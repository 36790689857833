import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Scrollchor from 'react-scrollchor';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Logo } from './images/GFZ_Logo.svg';
import { HashLink as Link } from 'react-router-hash-link';

import navigation from '../data/navigation';
import LanguageSwitch from './languageSwitch';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this._handleClick = this._handleClick.bind(this);
  }

  static defaultProps = {
    isExternal: false
  }

  _handleClick(lang) {
    if (typeof this.props.onChangeLanguage === 'function') {
      this.props.onChangeLanguage(lang);
    }
  }

  render() {
    let navItems;

    if (this.props.isExternal) {
      navItems = navigation.map((navItem, index) => (
        <Nav.Item key={'nav' + index}>
          <Link to={'/#' + navItem.linkTo} className='nav-link' scroll={el => el.scrollIntoView({ behavior: 'auto', block: 'start' })}>
            <FormattedMessage id={navItem.id}
              defaultMessage={navItem.defaultMessage}
              description={navItem.description} />
          </Link>
        </Nav.Item>
      ));
    } else {
      navItems = navigation.map((navItem, index) => (
        <Nav.Item key={'nav' + index}>
          <Scrollchor to={'#' + navItem.linkTo} className='nav-link'>
            <FormattedMessage id={navItem.id}
              defaultMessage={navItem.defaultMessage}
              description={navItem.description} />
          </Scrollchor>
        </Nav.Item>
      ));
    }

    return (
      <Navbar bg='light' expand='lg'>
        <Navbar.Brand href={this.props.rootLink}>
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav'>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='justify-content-end'>
            {navItems}
            {!this.props.isExternal &&
              <Nav.Item>
                <LanguageSwitch />
              </Nav.Item>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Navigation;