import React from 'react';
import { FormattedMessage } from 'react-intl';

class TeaserComponent extends React.Component {

  static defaultProps = {
    wrapperClasses: 'col-lg-6 mb-5 teaser',
    linkTarget: '_blank',
    linkRel: 'noopener noreferrer',
    teaserImageClasses: 'teaser-image teaser-image-clickable',
    teaserHeadlineDefaultMessage: 'Default Headline Message',
    teaserHeadlineDescription: 'Default Headline Description',
    teaserTextDefaultMessage: 'Default Text Message',
    teaserTextDescription: 'Default Text Description',
    buttonClasses: 'button textlink',
    buttonTextDefaultMessage: 'Default Button Message',
    buttonTextDescription: 'Default Button Description'
  }

  render() {
    return (
      <div className={this.props.wrapperClasses}>
        <a href={this.props.link} target={this.props.linkTarget} rel={this.props.linkRel} alt=''>
          <img alt='' className={this.props.teaserImageClasses} src={this.props.teaserImageSrc} />
        </a>
        <h3 className='teaser-headline'>
          <FormattedMessage id={this.props.teaserHeadlineId} defaultMessage={this.props.teaserHeadlineDefaultMessage} description={this.props.teaserHeadlineDescription} />
        </h3>
        <p className='lead'>
          <FormattedMessage id={this.props.teaserTextId} defaultMessage={this.props.teaserTextDefaultMessage} description={this.props.teaserTextDescription} />
        </p>
        <a href={this.props.link} target={this.props.linkTarget} rel={this.props.linkRel} alt='' className={this.props.buttonClasses}>
          <FormattedMessage id={this.props.buttonTextId} defaultMessage={this.props.buttonTextDefaultMessage} description={this.props.buttonTextDescription} />
        </a>
      </div>
    );
  }
}

export default TeaserComponent;